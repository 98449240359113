<template>
  <div class="layout-settings">

    <div class="form-title">{{ $t('settings.advanced.adb-settings') }}</div>

    <el-form :model="formData" label-position="left" label-width="110px" class="form-settings">

      <el-form-item :label="$t('settings.advanced.adb')">
        <el-button-group>
          <el-button @click="onEnableADB(true)" type="primary">{{ $t('settings.advanced.enable') }}</el-button>
          <el-button @click="onEnableADB(false)" type="primary">{{ $t('settings.advanced.disable') }}</el-button>
        </el-button-group>
      </el-form-item>


      <el-form-item :label="$t('settings.advanced.otg')">
        <el-button-group>
          <el-button @click="onEnableOTG(true)" type="primary">{{ $t('settings.advanced.enable') }}</el-button>
          <el-button @click="onEnableOTG(false)" type="primary">{{ $t('settings.advanced.disable') }}</el-button>
        </el-button-group>
      </el-form-item>


      <!--<el-form-item label="ADB">-->
        <!--<el-switch active-text="On" inactive-text="Off" v-model="adbEnable"></el-switch>-->
      <!--</el-form-item>-->

    </el-form>
  </div>
</template>

<script>
  export default {
    name: "ADBSettings",

    data: function(){
      return {
        adbEnable: false
      }
    },

    props: {
      formData: {}
    },

    methods: {
      onEnableADB(enable){
        this.$store.dispatch("enableADB", enable).then(response => {
          this.$message({type: "success", message: response.message})
        }).catch(reason => {
          this.$message({type: "error", message: reason})
        })
      },

      onEnableOTG(enable){
        this.$store.dispatch("enableOTG", enable).then(response => {
          this.$message({type: "success", message: response.message})
        }).catch(reason => {
          this.$message({type: "error", message: reason})
        })
      }
    }
  }
</script>

<style scoped>
  @import "../../css/general.css";
</style>