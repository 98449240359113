<template>
  <div>
    <adb-settings :form-data="adb"></adb-settings>
  </div>
</template>

<script>
  import AdbSettings from './components/ADBSettings'
  export default {
    name: "Advanced",

    components: {
      adbSettings: AdbSettings
    },

    data: function () {
      return {
        adb: {

        }
      }
    }
  }
</script>

<style scoped>

</style>