var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-settings" },
    [
      _c("div", { staticClass: "form-title" }, [
        _vm._v(_vm._s(_vm.$t("settings.advanced.adb-settings")))
      ]),
      _c(
        "el-form",
        {
          staticClass: "form-settings",
          attrs: {
            model: _vm.formData,
            "label-position": "left",
            "label-width": "110px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("settings.advanced.adb") } },
            [
              _c(
                "el-button-group",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.onEnableADB(true)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("settings.advanced.enable")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.onEnableADB(false)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("settings.advanced.disable")))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("settings.advanced.otg") } },
            [
              _c(
                "el-button-group",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.onEnableOTG(true)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("settings.advanced.enable")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.onEnableOTG(false)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("settings.advanced.disable")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }